<template>
    <div class="eatBindCard">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['save','readCard','refresh','goBack']"
                @saveClick="saveData()"  
                @readCardClick="readCard()"
                @refreshClick="loadData()" 
                @goBackClick="$router.back()"/>
            <el-checkbox v-model="isNotBingCard" label="仅显示未绑卡的菜品" ></el-checkbox>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="left-table table-box">
                    <el-tree
                        ref="treeRef"
                        class="filter-tree"
                        :data="treeData"
                        :expand-on-click-node="false"
                        node-key="id"
                        highlight-current
                        default-expand-all
                        @current-change="currentChange"
                    />
                </div>
                <div class="right-table ">
                    <div class="table-box">
                        <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                            border
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            :data="pageTableData"
                            highlight-current-row
                            @current-change="handleCurrentChange"
                        >
                            <el-table-column label="序号" width="40" >
                                <template #default="scope">{{scope.$index+1+(tablePageIndex*pageSize)}}</template>
                            </el-table-column>
                            <el-table-column class-name="Food_Code" prop="Food_Code" label="编码" min-width="100" align="left"></el-table-column>
                            <el-table-column class-name="Food_Name" prop="Food_Name" label="名称" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="isBindCard" label="是否已绑卡" width="90">
                                <template #default="scope">
                                    <template v-if="scope.isExcel">
                                        {{scope.row.isBindCard?"是":"否"}}
                                    </template>
                                    <span class="isBindCard" v-else>
                                        <i class="iconfont icon-gou" v-if="scope.row.isBindCard"></i>
                                        <i class="iconfont icon-cha" v-else></i>
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="search-box">
                        <key-board-input class="search"
                            type="text" v-model="searchTxt" placeholder="菜品名称、编码或助记码模糊检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                            @keydown.enter="onEnter()"
                        ></key-board-input>
                        <div class="float-right">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                            <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                            <div class="page-bnt">
                                <el-select v-model="pageSize"  >
                                    <el-option :value="20" label="20条/页"></el-option>
                                    <el-option :value="30" label="30条/页"></el-option>
                                    <el-option :value="50" label="50条/页"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-table">
                    <div class="table-box">
                        <el-table class="el-table--scrollable-y" style="width: 100%;height:100%"  
                            border
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            :data="cardTable"
                            @selection-change="selectCard"
                            :row-class-name="({row})=> {return row.isNew?'newCard':''}"
                        >
                            <el-table-column label="选择" type="selection" width="40" />
                            <el-table-column prop="Card_NO" label="已绑定卡号" min-width="120" align="left"></el-table-column>
                            <el-table-column width="40">
                                <template #header>
                                     <i class="iconfont icon-shanchu" @click="delData()"></i>
                                </template>
                                <template #default="scope">
                                    <i class="iconfont icon-shanchu" @click="delData(scope)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </div>
</template>

<script>
export default {
    name:"eatBindCard",
    data() {
        let pageSize= this.$cacheData.get("eatBindCard_pageSize")||20;
        return {
            //选中的节点
            currentNode:null,
            foodMainCategory:[],
            tablePageIndex:0,
            pageSize:pageSize,
            //搜索数据
            searchTxt:"",
            //仅显示未绑卡的菜品
            isNotBingCard:false,
            //菜品对应卡号数据
            foodCards:[],
            /**卡数据 */
            cardTable:[],
            /**卡信息 */
            cardConfig:null,
            //选中的菜品
            currentInfo:null,
            /**勾选的卡号 */
            delCards:[],
        }
    },
    computed:{
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    //Is_SetMeal 排除套餐数据
                    if(sub.PosFoods?.some(f=>!f.Is_SetMeal)){
                        children.push({
                            id:sub.SubCategory_ID,
                            label:sub.SubCategory_Code+"-"+sub.SubCategory_Name,
                            SubCategory_ID:sub.SubCategory_ID,
                            //PosFoods:sub.PosFoods
                        })
                    }
                });
                if(children.length>0){
                    tree.push({
                        id:category.MainCategory_ID,
                        label:category.MainCategory_Code+"-"+category.MainCategory_Name,
                        children:children
                    });
                }
            })
            return [{label:"全部",children:tree,id:0}];
        },
        /**所有菜品 */
        PosFoods(){
            let posFoods=[];
            this.foodMainCategory?.forEach(category=>{
                category.FoodSubCategorys?.forEach(sub=>{
                    sub.PosFoods.forEach(t=>{
                        posFoods.push(t);
                        if(t.MultipleSizeFoods?.length>0){//多规格
                            t.MultipleSizeFoods.forEach(it=>{
                                if(it.Food_ID!=t.Food_ID){
                                    posFoods.push(it);
                                }
                            })
                        }
                    })
                });
            })
            return posFoods;
        },
        tableData(){
            let posFoods=[];
            if(!this.currentNode){
                posFoods=this.PosFoods;
            }
            else if(this.currentNode?.children){
                let foods=[];
                let category= this.foodMainCategory.find(t=>t.MainCategory_ID==this.currentNode.id);
                if(category){
                    category.FoodSubCategorys?.forEach(f=>{
                        foods=foods.concat(f.PosFoods);
                    });
                }
                posFoods= foods;
            }else{
                posFoods=this.PosFoods.filter(t=>t.SubCategory_ID==this.currentNode.id);
            }

            posFoods?.forEach(it=>{
                it.isBindCard=this.foodCards?.some(card=>card.EAT_XFBMID==it.Food_ID); 
            });
            return posFoods.filter(t=>!t.Is_SetMeal);
        },
        filterData(){
            let data=this.tableData||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            if(this.isNotBingCard){
                data=data.filter(it=>!it.isBindCard);
            }
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.filterData.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.filterData.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("eatBindCard_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        currentInfo(newVal){
            if(newVal?.Food_ID){
                this.cardTable= this.foodCards.filter(it=>it.EAT_XFBMID==newVal.Food_ID);
            }else{
                this.cardTable=[];
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.$cacheData.AllPosFoods().then((d)=>{
            this.currentNode=null;
            this.foodMainCategory=d||[];
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        this.loadData();
        this.$webBrowser.getReadCardOrder().then(d=>{//获取电子秤配置信息
            if(d){
                this.cardConfig={
                    isEnableReadCard:d.isEnableReadCard==true,
                    brand:d.brand||1,
                    port:d.port||1,
                    baud:parseInt(d.baud)||57600
                }
            }
        });
    },
    methods:{
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetFoodBindDtCards", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    console.log(d.ResponseBody)
                    this.foodCards=d.ResponseBody;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("菜品绑定的卡号数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**上级分类 */
        getCategory(row){
            let name=""
            if(!this.currentNode){
                this.treeData[0].children.find(category=>{
                    let sub=category.children.find(t=>t.SubCategory_ID==row.SubCategory_ID);
                    if(sub){
                        name=sub.label;
                        return true;
                    }
                    return false;
                })
            }
            else if(this.currentNode?.children){
                let sub=this.currentNode?.children.find(t=>t.SubCategory_ID==row.SubCategory_ID);
                if(sub){
                    name=sub.label;
                }
            }else{
                name=this.currentNode.label;
            }
            return name;
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        //选中菜品
        handleCurrentChange(currentRow){
            this.currentInfo=currentRow;
        },
        //勾选的卡号
        selectCard(currentRow){
            this.delCards=currentRow;
        },
        /**保存修改 */
        saveData(){
            let Card_NOList=this.cardTable?.map(it=>it.Card_NO);
            
            if(!this.currentInfo){
                this.$message.warning('数据无修改！请先选择要绑定的菜品');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let data={
                Operator_Name:userInfo?.Login_Name,
                Eat_XFBMID:this.currentInfo.Food_ID,
                Card_NOList:Card_NOList
            }
            this.$httpAES.post("Bestech.CloudPos.SetFoodBindDtCards",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");

                    for(let i=0;i<this.foodCards.length;i++){
                        if(this.foodCards[i].EAT_XFBMID==data.Eat_XFBMID || Card_NOList.indexOf(this.foodCards[i].Card_NO)>=0){
                            this.foodCards.splice(i,1);
                            i--;
                        }
                    }

                    Card_NOList.forEach(id=>{
                        this.foodCards.push({
                            EAT_XFBMID:data.Eat_XFBMID,
                            Card_NO:id
                        });
                    })
                    this.cardTable= this.foodCards.filter(it=>it.EAT_XFBMID==data.Eat_XFBMID);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败：",e);
            })
        },
        //读卡
        readCard(){
            if(this.cardConfig==null){
                this.$message.warning('读卡点菜未配置!');
                return;
            }
            const loading = this.$loading({
                text: "读卡中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$webBrowser.readCardOrder(this.cardConfig.brand,this.cardConfig.port,this.cardConfig.baud).then(d=>{//读卡器开启成功
                loading.close();
                if(d && d.state==0){
                    if(d.data?.length>0){
                        d.data.forEach((id)=>{
                            if(!this.cardTable?.some(it=>it.Card_NO==id)){//添加不重复的卡id
                                this.cardTable.push({Card_NO:id,isNew:true});
                            }
                        })
                    }else{
                        this.$message.warning('未读到卡数据!');
                    }
                }else{
                    this.$message.warning(d?.message?('读卡失败：'+d.message):'读卡失败');
                }
            });
        },
        delData(data){
            if(data){
                this.cardTable.splice(data.$index,1);//移除
            }else{//删除勾选的
                this.delCards?.forEach(it=>{
                    let index=this.cardTable.findIndex(row=>it.Card_NO==row.Card_NO);
                    if(index>=0){
                        this.cardTable.splice(index,1);
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss">
@import './eatBindCard.scss'
</style>